<template>
  <Modal ref="productModal" size="lg" id="ViewProduct" title="View Products">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6"><filter-box v-model.trim="searchText"  placeholder="Search Products..."></filter-box></div>
      
    </div>
     
      <div class="py-3">

      <div class=""  style="max-height:350px; overflow-y:scroll"  >
           <b-skeleton-table
                v-if="isLoading&&isNextCollectionProduct==null"
                :rows="5"
                :columns="2"
                :table-props="{ bordered: true }"></b-skeleton-table>
          <b-table
                v-else-if="productFilter.length>0"
                head-class="text-center"
                class="leadTable"
                :stickyColumn="true"
                hover
                :fields="columns"
                :items="productFilter"
               >
                <template #cell(image)="data">
                  <LazyImage
                    v-if="checkFileType(data.item.image) != 'video'"
                    :src="
                      data.item.image
                        ? data.item.image
                        : 'https://cdn.shopify.com/s/files/1/0554/9676/9614/files/default.png?v=1663245344'
                    "
                    :key="data.item.image"
                    style="
                      height: 50px;
                      width: 50px;
                      border-radius: 50%;
                      cursor: pointer;
                    "
                    alt="" />
                  <video
                    v-else
                    ref="videoRef"
                    :src="`${data.item.image}`"
                    style="
                      object-fit: cover;
                      border-radius: 6px;
                      height: 50px;
                      width: 50px;
                      cursor: pointer;
                    "
                    controls></video>
                </template>
                <template #cell(title)="data">
                  <div
                    style="cursor: pointer; width: fit-content"
                   >
                    {{ data.item.title }}
                  </div>
                </template>
          
               
                
               
              </b-table>
               <template v-else-if="!isNextCollectionProduct">
                

<div class="d-flex justify-content-center align-items-center">

<EmptyTable >
<template #title>
No product found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

                </template>

                <template > 
                  <!--  -->
                  <div v-if="isLoading&&isNextCollectionProduct!=null" class="d-flex text-center justify-content-center align-items-center">
                  
                    <b-spinner small></b-spinner>
                    <span class="px-2">
                    Loading More Products...
                  </span>
                  </div>
                </template>
      </div>
      </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import EmptyTable from '../Layout/EmptyTable.vue'
import FilterBox from '../Layout/FilterBox.vue'
import Modal from '../Layout/Modal.vue'

export default{
  data(){
      return {
      withVariant:false,
        isLoadingSyncProduct:false,
        collectionId:null,
            columns: [
    
      {
        key: "image",
        label: "Image",
        tdClass: "align-middle data-Table cursor-pointer",
        thClass: "bg-table-heading",
      },
      {
        key: "title",
        label: "Title",
        tdClass: "align-middle data-Table cursor-pointer",
        thClass: "bg-table-heading",
      },
     
      
    ],
    products:[],
    searchText:'',
    isLoading:false,
    isNextCollectionProduct:null,
      }
  },
props:{
 category:String
},
components:{
  Modal,
  EmptyTable,
  FilterBox,
 
},
computed:{
   ...mapGetters( ["getIsShopifyUser"]),
  productFilter(){
    return  this.products.filter(product=>product.title.toLowerCase().includes(this.searchText.toLowerCase())||this.search=='')
  },
},
methods:{
  // handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
  //   if ((scrollTop + clientHeight)+1 >= scrollHeight&&(!this.isLoading)&&(this.isNextCollectionProduct)) {
  //     this.getProducts()
  //   }
  // },
checkFileType(file) {
    let fileType;
    let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    let allowedVideoExtensions = /(\.mp4)$/i;

    if (!allowedImageExtensions.exec(file)) {
      if (allowedVideoExtensions.exec(file)) {
        fileType = "video";
      } else {
        fileType = "unknown";
      }
    } else {
      fileType = "image";
    }

    return fileType;
  },
 async importCollectionProducts(){
  this.isLoadingSyncProduct=true;
   await axios.post('/collection/'+this.collectionId+'/sync-shopify-products/',{ withVariant:this.withVariant}).then(response=>{
      if(response.status)
      {
         this.$refs.productModal.close();
         this.$toasted.show(response.data.data, {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
      else{
         this.$toasted.show("Error occurred while sync ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    }).catch(()=>{
      this.$toasted.show("Error occurred while sync ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
    })
    this.isLoadingSyncProduct=false;
  },
  openModal(id){
    this.withVariant=false;
    this.collectionId=id;
    this.isNextCollectionProduct=null;
     this.$refs.productModal.open();
     this.products=[];
     this.getProducts(id)
  },
async getProducts(id=''){

    this.isLoading=true;
    let obj={[this.category+"_id"]:id}
       this.products = await axios.post(this.category+'/products',obj).then(resp=>{
        if(this.category=='collection')
        {
          return resp.data.data.products
        }
        resp.data.data
      }).catch(()=>[]);
  //     if(this.getIsShopifyUser)
  //     {
  //       if(this.isNextCollectionProduct)
  //       await axios.get('loadMoreShopifyCollectionProducts/'+this.isNextCollectionProduct).then(resp=>{
  //   this.isNextCollectionProduct = resp.data.data.next
  //    this.products  = [... this.products ,...resp.data.data.products]
  //   }).catch(()=>[])
  //   else
  //   await axios.get('getCollectionProducts/'+id).then(resp=>{
    
  //  this.isNextCollectionProduct = resp.data.data.next
  //    this.products  = resp.data.data.products
  //   }).catch(()=>[])
  //     }
  //     else{
  //       let obj={[this.category+"_id"]:id}
  //        this.products = await axios.post(this.category+'/products',obj).then(resp=>resp.data.data).catch(()=>[]);
  //     }
      // let category=this.category+'_id'
       this.isLoading=false;
      
  }
},
mounted(){
 
}
}
</script>
<style scoped>
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
color: #fff;
border-color: #ffa201;
background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
box-shadow: 0 0 0 0.2rem #ffa201;
}
</style>